import LoginRegister from '@components/login-register/LoginRegister';
import { ContentBlock } from '@layouts/ContentBlocks';
import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import { useRouter } from 'next/router';
import React from 'react';

export default function Login() {
  const router = useRouter();
  const { theme } = useStyles();

  return (
    <ContentBlock maxWidth={600}>
      <Typography
        variant="h3Alt"
        component="h1"
        style={{ marginBottom: theme.spacing(3) }}
      >
        Create an account or log in to save items, checkout and more with Guest House.
      </Typography>

      <LoginRegister
        defaultView="login"
        onLoginSuccess={() => router.push('/')}
      />
    </ContentBlock>
  );
}
